// 重复项目列表
<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <base-material-card
      inline
      color="indigo"
      icon="mdi-clipboard-text"
      title="重复项目列表"
      class="mb-0 px-2 px-sm-5 px-md-5 px-lg-5 px-xl-5 py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3 mt-12"
    >
      <v-simple-table>
        <thead>
          <tr
            :class="isXs ? 'titles' : ''"
          >
            <th style="min-width:140px">
              名称
            </th>
            <th
              style="min-width:84px"
            >
              产业类别
            </th>
            <th
              style="min-width:84px"
            >
              创建时间
            </th>
            <th
              style="min-width:84px"
            >
              提报用户
            </th>
            <th
              style="min-width:150px"
              class="text-right"
            >
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(project, index) in projectList"
            :key="index"
            :class="isXs ? 'names' : ''"
          >
            <td>{{ project.name }}</td>
            <td>{{ project.data.industry }}</td>
            <td>{{ project.createdAt | dateformat }}</td>
            <td>{{ project.nickname }}</td>
            <td class="text-right">
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="error"
                @click="deleteProject(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-trash-o
                </v-icon>
                删除
              </v-btn>
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="success"
                :to="`report-details/${project.formId}/${project.id}`"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                查看
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="py-3" />
  </v-container>
</template>
<script>
  export default {
    data: () => ({
      projectList: [],
      formIds: '',
      isXs: false,
    }),
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    created () {
      this.getProjectList()
    },
    methods: {
      getProjectList () { // 获取项目列表
        if (JSON.parse(localStorage.newForms).length) {
          const list = JSON.parse(localStorage.newForms)[0].children
          const formIds = []
          list.map(val => {
            formIds.push(val.id)
          })
          this.formIds = formIds.toString()
        }
        this.$axios.get('/reports/duplicate', {
          params: {
            formId: this.formIds,
          },
        })
          .then((res) => {
            console.log(res)
            this.projectList = res.data.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      deleteProject (index) { // 删除项目
        this.$swal({
          title: '你确定要删除此项目吗？',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            console.log(this.projectList[index])
            this.$axios.delete('/reports/' + this.projectList[index].id)
              .then((response) => {
                this.projectList.splice(index, 1)
                this.$swal({
                  title: '删除成功!',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
      },
      seeProject (index) { // 查看项目
        this.$router.push({
          name: 'Details',
          params: {
            formId: this.projectList[index].formId,
            id: this.projectList[index].id,
          },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>

.names td{
font-size: 14px !important;
font-family: PingFang SC, PingFang SC-Medium;
color: #333333;
padding: 0 6px !important;
}
.titles th{
opacity: 0.6;
font-size: 13px !important;
font-family: PingFang SC, PingFang SC-Medium;
font-weight: 500;
color: #333333 !important;
padding:0 6px !important
}
</style>
